import axios from "axios"
import qs from "qs";
import cookies from "./cookies";

//axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';//"application/json; charset=UTF-8";//;
if (process.env.NODE_ENV == 'development') {    
    //axios.defaults.baseURL = 'http://baoming.taianting.com:4380/api/registration';
    axios.defaults.baseURL = 'http://api.chwh.com';//'http://api.chwh.com/';//'http://api.dev.cn';
}else if (process.env.NODE_ENV == 'debug') {    
    axios.defaults.baseURL = '';
}else if (process.env.NODE_ENV == 'production') {    
    axios.defaults.baseURL = 'https://www.jianmulee.com/api';//http://114.55.234.147/api  
}

//请求拦截器
axios.interceptors.request.use(function (config) {
    if (config.method == "get" || config.method == "GET" ||
        config.method == "options" || config.method == "OPTIONS" ||
        config.method == "delete" || config.method == "DELETE" ||
        config.method == "request" || config.method == "REQUEST" ||
        config.method == "head" || config.method == "HEAD") {
        config.params = config.data;
        delete config.data;
    }
    // if (config.method == 'post' || config.method == 'POST') {
    //     if (typeof (config.data) == "object") {
    //         switch (config.headers["Content-Type"]) {
    //             case "application/x-www-form-urlencoded":
    //                 config.data = qs.stringify(config.data);
    //                 break;
    //             case "application/json":
    //                 config.data = JSON.stringify(config.data);
    //                 break;
    //             case "multipart/form-data":
    //                 break;
    //             default:
    //                 break;
    //         }
    //     }
    // }
    let token = cookies.token();
    if (token != null) {
        config.headers["Authorization"] = token;
    }
    return config;
}, function (error) {
    return Promise.reject(error);// 对请求错误做些什么
});

//响应拦截器
axios.interceptors.response.use(function (res){
    // 处理响应数据
        if (res.status === 200) {            
            return Promise.resolve(toObject(res.data));        
        } else {            
            return Promise.reject({ "code": -1, "error": "错误状态码："+res.status });        
        }
    }, function (error){
   // 处理响应失败
   return Promise.reject(error);
});


//axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

//axios.defaults.timeout = 10000;

function toObject(str) {
    var bcDt = str;
    if(str && Object.prototype.toString.call(str) == '[object String]'){
        bcDt = JSON.parse(str);
    }
    return bcDt;
}

function http(obj){
    // console.log(JSON.stringify(store.state.user));
    return new Promise((resolve,reject)=>{
        try {
            obj.headers = obj.headers || {};
            if (!obj.headers['Content-Type']) {
                obj.headers['Content-Type'] = "application/x-www-form-urlencoded";
            }
            axios(obj).then(data => {
                if (data.status) {
                    resolve(data.data);
                } else {
                    reject(errorHandler(data.error));
                }
            }).catch(err=>{
                reject(errorHandler(err));
            })
        }catch(err){
            reject(errorHandler(err));
        }
    });
}

function errorHandler(err){
    var e = JSON.parse(JSON.stringify(err));
    if(e.message=="Network Error"){
        return { "code": -1, "message": "请求失败！" };
    } else {
        return err;
    }
}

function get(obj) {
    obj.method = 'GET';
    obj.headers = obj.headers || {};
    if (!obj.headers['Content-Type']) {
        obj.headers['Content-Type'] = "application/x-www-form-urlencoded";
    }
    return http(obj);
}

function post(obj) {
    obj.method = 'POST';
    obj.headers = obj.headers || {};
    if (!obj.headers['Content-Type']) {
        obj.headers['Content-Type'] = "application/x-www-form-urlencoded";
    }
    if (obj.data && typeof(obj.data) == "object") {
        obj.data = qs.stringify(obj.data);
    }
    return http(obj);
}

function postJson(obj) {
    obj.method = 'POST';
    obj.headers = obj.headers || {};
    if (!obj.headers['Content-Type']) {
        obj.headers['Content-Type'] = "application/json";
    }
    if (obj.data && typeof(obj.data) == "object") {
        obj.data = JSON.stringify(obj.data);
    }
    return http(obj);
}

function postFile(obj) {
    obj.method = 'POST';
    obj.headers = obj.headers || {};
    if (!obj.headers['Content-Type']) {
        obj.headers['Content-Type'] = "multipart/form-data";
    }
    return http(obj);
}

//auth

function login(data){
    let obj = {
        url: "/auth/login",
        data: data
    }
    return post(obj);
}

function logout(data){
    let obj = {
        url: "/auth/logout",
        data: data
    }
    return post(obj);
}

//utils

function getUtilsDateTime(data) {
    let obj = {
        url: "/utils/datetime",
        params: data
    }
    return get(obj);
}

function postUtilsUpload(data) {
    let obj = {
        url: "/utils/upload",
        data: data
    }
    return postFile(obj);
}

function postUtilsRemove(data) {
    let obj = {
        url: "/utils/remove",
        data: data
    }
    return post(obj);
}

function getRegionList(data) {
    let obj = {
        url:"/utils/region/list"
    }
    return get(obj);
}

//role

function getRoleList(data) {
    let obj = {
        url: "/role/list",
        data:data
    }
    return get(obj);
}

function postRoleSave(data) {
    let obj = {
        url: "/role/save",
        data:data
    }
    return postJson(obj);
}

//menu
function getMenuCompanyAll(data) {
    let obj = {
        url: "/menu/company/all",
        data:data
    }
    return get(obj);
}

function getCompanyRoleMenuList(data) {
    let obj = {
        url: "/menu/company/role/list",
        data:data
    }
    return get(obj);
}

function getMenuList(data) {
    let obj = {
        url: "/menu/list",
        data:data
    }
    return get(obj);
}

//company

function getCompanyList(data){
    let obj = {
        url: "/company/list",
        data: data
    }
    return get(obj);
}

function getCompanyPersonList(data){
    let obj = {
        url: "/company/person/list",
        data: data
    }
    return get(obj);
}

function postCompanySave(data){
    let obj = {
        url: "/company/save",
        data: data
    }
    return post(obj);
}

function getCompanyShopList(data) {
    let obj = {
        url: '/company/shop/list',
        data: data,
    }
    return get(obj);
}

function postCompanyShopSave(data) {
    let obj = {
        url: '/company/shop/save',
        data: data,
    }
    return postJson(obj);
}

function getCompanyStorageList(data) {
    let obj = {
        url: '/company/storage/list',
        data: data,
    }
    return get(obj);
}

function postCompanyStorageSave(data) {
    let obj = {
        url: '/company/storage/save',
        data: data,
    }
    return postJson(obj);
}


function getCompanyProviderList(data) {
    let obj = {
        url: '/company/provider/list',
        data: data,
    }
    return get(obj);
}

function postCompanyProviderSave(data) {
    let obj = {
        url: '/company/provider/save',
        data: data,
    }
    return post(obj);
}

//user

function getUserSingle(data){
    let obj = {
        url: "/user/single",
        data: data
    }
    return get(obj);
}

function getCompanyUserList(data) {
    let obj = {
        url: "/user/company/list",
        data: data
    }
    return get(obj);
}

function postUserSave(data){
    let obj = {
        url: "/user/save",
        data: data
    }
    return post(obj);
}

//product

function getProductSpeciList(data){
    let obj = {
        url: "/product/speci/list",
        data: data
    }
    return get(obj);
}

function postProductSpeciSave(data){
    let obj = {
        url: "/product/speci/save",
        data: data
    }
    return postJson(obj);
}

function getProductUnitList(data){
    let obj = {
        url: "/product/unit/list",
        data: data
    }
    return get(obj);
}

function postProductUnitSave(data){
    let obj = {
        url: "/product/unit/save",
        data: data
    }
    return post(obj);
}

function getProductAttrList(data){
    let obj = {
        url: "/product/attr/list",
        data: data
    }
    return get(obj);
}

function postProductAttrSave(data){
    let obj = {
        url: "/product/attr/save",
        data: data
    }
    return postJson(obj);
}

function getProductCategoryList(data){
    let obj = {
        url: "/product/category/list",
        data: data
    }
    return get(obj);
}

function postProductCategorySave(data){
    let obj = {
        url: "/product/category/save",
        data: data
    }
    return post(obj);
}

function getProductList(data){
    let obj = {
        url: "/product/list",
        data: data
    }
    return get(obj);
}

function getProductSingle(data){
    let obj = {
        url: "/product/single",
        data: data
    }
    return get(obj);
}

function getProductUnits(data){
    let obj = {
        url: "/product/units",
        data: data
    }
    return get(obj);
}

function postProductSave(data) {
    let obj = {
        url: "/product/save",
        data: data
    }
    return postJson(obj);
}

//stock


//order
function getOrderPurchaseList(data) {
    let obj = {
        url: "/order/purchase/list",
        data: data
    }
    return get(obj);
}

function getOrderPurchaseDetails(data) {
    let obj = {
        url: "/order/purchase/details",
        data: data
    }
    return get(obj);
}

function postOrderPurchaseSave(data) {
    let obj = {
        url: "/order/purchase/save",
        data: data
    }
    return postJson(obj);
}

function getOrderGodownList(data) {
    let obj = {
        url: "/order/godown/list",
        data: data
    }
    return get(obj);
}

function getOrderGodownDetails(data) {
    let obj = {
        url: "/order/godown/details",
        data: data
    }
    return get(obj);
}

function postOrderGodownSave(data) {
    let obj = {
        url: "/order/godown/save",
        data: data
    }
    return postJson(obj);
}


//trace 
function getTraceStoreList(data) {
    let obj = {
        url: "/trace/store/list",
        data: data
    }
    return get(obj);
}

function addTraceStore(data) {
    let obj = {
        url: "/trace/store/add",
        data: data
    }
    return post(obj);
}

function getTraceSaleList(data) {
    let obj = {
        url: "/trace/sale/list",
        data: data
    }
    return get(obj);
}

function addTraceSale(data) {
    let obj = {
        url: "/trace/sale/add",
        data: data
    }
    return post(obj);
}

function addTraceRegList(data) {
    let obj = {
        url: "/trace/scan/list",
        data: data
    }
    return get(obj);
}

//record

function getRecordList(data) {
    let obj = {
        url: "/record/list",
        data:data
    }
    return get(obj);
}

function getRecordPerson(data) {
    let obj = {
        url: "/record/person",
        data:data
    }
    return get(obj);
}

//agent

function getAgentList(data) {
    let obj = {
        url: "/company/agent/list",
        data:data
    }
    return get(obj);
}

function postAgentSave(data) {
    let obj = {
        url: "/company/agent/save",
        data:data
    }
    return postJson(obj);
}

export default {    
    login,//登录系统
    logout,//登出
    getUtilsDateTime,//获取服务器时间
    postUtilsUpload,//上传文件
    postUtilsRemove,//删除文件
    getRegionList,//获取行政地区列表
    //role
    getRoleList,//获取角色列表
    postRoleSave,//保存角色
    //menu
    getMenuCompanyAll,//公司能看到的菜单列表
    getCompanyRoleMenuList,//公司自定义角色的菜单列表
    getMenuList,//获取菜单列表
    getCompanyList,//获取单位列表
    getCompanyPersonList,//获取个人所属公司列表
    postCompanySave,//保存单位
    getCompanyShopList,//获取门店列表
    postCompanyShopSave,//保存门店列表
    getCompanyStorageList,//获取仓库列表
    postCompanyStorageSave,//保存仓库
    getCompanyProviderList,//获取供应商列表
    postCompanyProviderSave,//保存供应商
    getUserSingle,//获取帐号信息
    getCompanyUserList,//获取单位所管理的用户列表
    postUserSave,//创建新用户
    getProductSpeciList,//获取产品规格列表
    postProductSpeciSave,//保存产品规格
    getProductUnitList,//获取产品单位列表
    postProductUnitSave,//保存产品单位
    getProductAttrList,//获取产品属性列表
    postProductAttrSave,//保存产品属性
    getProductCategoryList,//根据parentid获取类别
    postProductCategorySave,//保存产品类别
    getProductList,//产品分页列表
    getProductSingle,//单个产品信息
    getProductUnits,//产品单位列表
    postProductSave,//保存产品
    getOrderPurchaseList,//获取采购单列表
    getOrderPurchaseDetails,//获取采购单明细
    postOrderPurchaseSave,//保存采购单
    getOrderGodownList,//获取入库单列表
    getOrderGodownDetails,//获取入库单明细
    postOrderGodownSave,//保存入库单
    getTraceStoreList,//追溯码库列表
    addTraceStore,//添加追溯信息
    getTraceSaleList,//追溯码出库列表
    addTraceSale,//追溯码出库
    addTraceRegList,//扫码登记列表
    getRecordList,//获取打卡列表
    getRecordPerson,//个人打卡记录
    getAgentList,//代理商列表
    postAgentSave,//保存代理商
}




